.bob-frame.contains-c1-form-contact,
.bob-frame.contains-e1-error-frame,
.bob-frame.contains-s1-bob-success {

    .content,
    .form-group-email {
        p {
            font-size: 15px;
            font-weight: 300;
        }
    }

    .mini {
        font-size: 16px;
    }
}

.form-group-email {
    
    label {
        white-space: nowrap;
    }
}

@include media-breakpoint-up(md) {

    .bob-frame.contains-c1-form-contact,
    .bob-frame.contains-e1-error-frame,
    .bob-frame.contains-s1-bob-success {
        .bob-window {
            width: 600px;
        }
    }   
} 

@mixin frame-margin($amount, $breakpoint){

    @include media-breakpoint-up(#{$breakpoint}) {
        .bob-frame.contains-bob-frame-content-passage {
            .bob-window {
                .bob-content {
                    p, .passge-frame-header {
                        margin: $amount 0;
                    }
                }
            }
        }
    } 
}

@include frame-margin(20px, xs);
@include frame-margin(25px, md);
@include frame-margin(30px, lg);
@include frame-margin(35px, xl);