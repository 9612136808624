// footer
// ******


// .footer margin-top should be 0, because of bottomizer

.footer {
    padding-bottom: 50px;
    padding-top: 100px;
    color: $brand-hero;
    font-size: 15px;
    background: $brand-footer;

    span {
        color: $brand-app;
        font-weight: 600;
    }

    hr {
        margin: 70px 0;
        width: 100%;
        height: 2px;
        background-color: $footer-row-separator;
    }

    .footer-column {
        padding-top: 50px;
        color: #fff;

        a,
        a:hover {
            text-decoration: none;
            color: #fff;
        }

        a {
            opacity: 0.7;
        }

        a:hover,
        a.active {
            opacity: 1;
        }

        ul {
            list-style: none;
            padding-left: 0;

            li {
                position: relative;
                padding-left: 13px;
            }

            .bullet {
                display: block;
                position: absolute;
                top: 11px;
                left: 0px;
                background: #fff;
                width: 6px;
                height: 6px;
                border-radius: 3px;
                opacity: 0.5;
            }

        }


    }

    .footer-logo {
        height: 100px;

        .gaia-logo {
            margin-top: 16px;
        }
    }

    .footer-legal {
        font-size: 70%;
        line-height: 1.3;
        margin-bottom: 14px;

        span {
            color: #fff;
            white-space: nowrap;
            font-weight: 400;
        }
    }

    .footer-formal-links {
        margin-top: 14px;
    }

}


// show popovers (socials) if overlapping .container (desktop)
.footer .footer-links .container {
    overflow: visible;
}

.footer-redirect {
    display: none;
}


//////// footer mobile-nav

.footer {
    .footer-col-mobile {
        h3 {
            font-size: 30px;
        }

        h3,
        p {
            width: 60%;
            margin: 0 auto;
        }

        p {
            padding-top: 40px;

            &:before {
                content: "";
                margin-bottom: 50px;
                width: 100%;
                height: 1px;
                background-color: rgb(177, 177, 177);
                display: block;
            }

            a {
                opacity: 1;
            }
        }
    }
}


@include media-breakpoint-up(sm) {

    .footer {
        .footer-col-mobile {
            h3,
            p {
                width: 100%;
                margin: 0;
            }

            p {
                &:before {
                    display: none;
                }

                a {
                    opacity: 0.7;
                }
            }
        }
    }
}

@include media-breakpoint-up (md) {
    .footer {
        .footer-col-mobile {
            display: table;
            margin: 0 0 0 auto;
        }
    }
}

@include media-breakpoint-up (lg) {

    .footer {
        padding-bottom: 100px;
    }
}

////////