.passage-devices {
    background-color: $brand-device-bg;
    font-family: $font-family-base;
    color: $brand-copy;
    @include padding-top;
    @include padding-bottom-half;

    .container {
        h2 {
            font-size: 28px;
            font-weight: 700;
            line-height: 41px;
        }

        h3 {
            font-size: 24px;
            font-weight: 700;
            line-height: 1.2;
            margin-bottom: 30px;
            margin-top: 60px;
        }

        p {
            font-size: 17px;
            line-height: 26px;
            font-weight: 400;
            margin-bottom: 30px;
        }

        .passage-image object {
            margin: auto;
            display: block;
            width: 100%;
            max-width: 430px;
            padding-top: 20px;
        }
        .passage-content {

            span {
                color: $brand-name;
                font-weight: 600;
            }

            .spot-heading {
                color: $spot-heading;
            }

            svg {
                width: 13px;
                height: auto;

                path {
                    stroke: $spot-heading;
                }
            }

            .row {
                margin-bottom: 25px;

                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}

// @media only screen and (min-width : 370px) {
//     .passage-devices {
//         .container {
//             padding: 0px 10% 70px 10%;
//         }
//     }
// }

// @include media-breakpoint-up(sm) {
//     .passage-devices {
//         .container {
//             padding: 0 0 50px 0;
//         }
//     }
// }


@include media-breakpoint-up (md) {

    .passage-devices {
        .container {

            .mobile-carousel {
                margin-top: 0;
            }

            .passage-content {
                h3 {
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 46px;
                    margin-top: 0;
                }
            }
        }
    }
}

@include media-breakpoint-up (lg) {
    .passage-devices {
        .container {
            h2 {
                font-size: 50px;
                line-height: 60px;
            }

            h3 {
                font-size: 40px;
            }
        }
    }
}

@include media-breakpoint-up (xl) {
    .passage-devices {
        .container {

            h2 {
                font-size: 54px;
            }

            .mobile-carousel {
                a {
                    min-height: 662px;
                }
            }
        }
    }
}