.hero {
    width: 100%;
    margin-bottom: 0;
    padding-top: 90px;
    @include padding-bottom-half;
    background-color: $brand-app;

    .title {
        padding-top: 40px;
        z-index: 1;

        h1 {
            color: #fff;
            font-size: 34px;
            line-height: 1.2;
            font-weight: 700;
            font-family: $font-family-base;
        }
    }

    .content {
        z-index: 1;
    }

    object {
        width: 80%;
        display: block;
        margin: auto;
        padding-top: 20px;
    }

    p {
        color: #fff;
        font-size: 18px;
        line-height: 1.3;
        font-weight: 400;
        font-family: $font-family-base;
        margin-bottom: 30px;
    }

    .container {
        padding: 0 10%;
    }

}


@include media-breakpoint-up(sm) {
    .hero {
        h1 {
            font-size: 40px;
        }
    }
}

@include media-breakpoint-up(md) {

    .hero {

        .container {
            padding: 0;
        }

        .grid {
            display: grid;
            grid-template-columns: 5fr 90px 3fr;;
            grid-template-rows: auto 1fr;
            grid-column-gap: 0;
            grid-row-gap: 0;
        }

        .title {
            padding-top: 70px;
            grid-area: 1 / 1 / 2 / 3;

            h1 {
                font-size: 42px;
                line-height: 1.28;
            }

            span {
                background-color: $brand-app;
                white-space: pre-wrap;

                span {
                    @include hyphens;
                }
            }
        }

        .content {
            grid-area: 2 / 1 / 3 / 2;
            z-index: 1;

            p {
                font-size: 22px;
            }
        }

        object {
            grid-area: 1 / 2 / 3 / 4;
        }
    }
}


@include media-breakpoint-up(lg) {
    .hero {

        .container {
            padding: 0 40px;
        }

        .grid {
            grid-template-columns: 5fr 145px 2fr;
        }

        .title {
            padding-top: 90px;

            h1 {
                font-size: 50px;
            }
        }


        .content {
            padding-right: 45px;

            p {
                font-size: 22px;
            }
        }
    }

}


@include media-breakpoint-up(xl) {
    .hero {

        .grid {
            grid-template-columns: 2fr 182px 1fr;
        }

        .title {
            padding-top: 100px;

            h1 {
                font-size: 60px;
            }
        }

        .content {
            p {
                font-size: 24px;
            }
        }
    }
}